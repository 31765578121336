import PageInfoItem from "./PageInfoItem"
import getRgbaValue from "../funcs/getRbgaValue"

export default function Contact({ colors }) {
  const style = { backgroundColor: getRgbaValue(colors.bg), color: getRgbaValue(colors.font) }
  return (
    <section style={style} className="container flex-container flex-content pt-4 pb-4">
      <PageInfoItem title='Feedback' body={<Suggestions/>} colors={colors}/>
      <PageInfoItem title='How' body={<ContactMethods colors={colors}/>} colors={colors}/>
      <PageInfoItem title='Support' body={<Coffee colors={colors}/>} colors={colors}/>
    </section>
  )
}

function Suggestions() {
  return (
    <div>
      <p>I would greatly appreciate any feedback you would like to provide. Especially, if you:</p>
      <ul>
        <li>spot any errors <i className="fa-solid fa-circle-exclamation"></i>,</li>
        <li>encounter any bugs <i className="fa-solid fa-bugs"></i>, or</li>
        <li>would like to see a new feature <i className="fa-regular fa-lightbulb"></i></li>
      </ul>
    </div>
  )
}

function ContactMethods({colors}) {
  const style = { color: getRgbaValue(colors.font) }
  return (
    <div>
      <p>Contact me by sending:</p>
      <ul>
        <li>an email to <a style={style} className='hyperlink' target="_blank" rel="noreferrer" href="mailto:wasimj.dev@gmail.com"><i className="fa-regular fa-envelope"></i></a>, or</li>
        <li>a direct message on <a style={style} className='hyperlink' target="_blank" rel="noreferrer" href="https://twitter.com/wa51mj"><i className="fa-brands fa-twitter"></i></a></li>
      </ul>
    </div>
  )
}

function Coffee({colors}) {
  const style = { color: getRgbaValue(colors.font) }
  return (
    <div>
      <p>If you found the application useful and would like to buy me a <i className="fa-solid fa-mug-hot"></i> you can do so <a style={style} className='hyperlink' target="_blank" rel="noreferrer" href="https://www.buymeacoffee.com/wasimj"><u>here</u></a>.</p>
    </div>
  )
}