export default function getDefaultColors() {
  return {
    font: getRgbaObj([255, 255, 255, 100]),
    fg: getRgbaObj([0,0,0, 100]),
    bg: getRgbaObj([108, 117, 125, 100]),
    bars: getRgbaObj([33, 37, 41, 100])
  }
}

function getRgbaObj(rgbaArr) {
  return {
    r: rgbaArr[0],
    g: rgbaArr[1],
    b: rgbaArr[2],
    a: rgbaArr[3],
  }
}

// font: white
// bg: black
// fg: bg-secondary
// bars: dark