import './App.css'
import React, { useState, useEffect } from 'react'
import Tabs from './comps/Tabs'
import Contact from './comps/Contact'
import ColorPicker from './comps/ColorPicker'
import getDefaultColors from './funcs/getDefaultColors'
import getRgbaValue from './funcs/getRbgaValue'

function App() {
  const [colors, setColors] = useState(getStoredColors())

  useEffect(() => {
    localStorage.setItem("colors", JSON.stringify(colors));
  }, [colors])

  const H = 'H'
  const D = 'D'
  const W = 'W'
  const M = 'M'
  const Y = 'Y'
  const [date, setDate] = useState(new Date())
  const [tab, setTab] = useState(Y);

  const getLogicalDay = day => (day === 0) ? 6 : day - 1 //  Sunday - Saturday : 0 - 6  makes more sense Monday - Sunday : 0 - 6
  const getDayOfYear = date => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24));

  const year = date.getFullYear()
  const yearDay = getDayOfYear(date) - 1 
  const month = date.getMonth()
  console.log(`month: ${month}`)
  const monthDay = date.getDate() - 1
  console.log(`month day: ${monthDay}`)
  const weekDay = getLogicalDay(date.getDay())
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate()
  console.log(`days in month: ${getDaysInMonth(year, month)}`)
  const getDaysInYear = year => (year % 4 !== 0) ? 366 : 365

  const secondsInMinute = 60
  const secondsInHour = secondsInMinute * 60
  const secondsInDay = secondsInHour * 24
  const secondsInWeek = secondsInDay * 7
  const secondsInMonth = secondsInDay * getDaysInMonth(year, month)
  const secondsInYear = secondsInDay * getDaysInYear(year)
  
  const secondsPastInMinute = seconds
  const secondsPastInHour = secondsPastInMinute + (minutes * secondsInMinute)
  const secondsPastInDay = secondsPastInHour + (hours * secondsInHour)
  const secondsPastInWeek = secondsPastInDay + (weekDay * secondsInDay)
  const secondsPastInMonth = secondsPastInDay + (monthDay * secondsInDay)
  const secondsPastInYear = secondsPastInDay + (yearDay * secondsInDay)

  const getPercentage = (num, den) => {
    const decimal = num / den
    const percentage = decimal * 100
    return percentage.toFixed(2)
  }

  const HResult = getPercentage(secondsPastInHour, secondsInHour)
  const DResult = getPercentage(secondsPastInDay, secondsInDay)
  const WResult = getPercentage(secondsPastInWeek, secondsInWeek)
  const MResult = getPercentage(secondsPastInMonth, secondsInMonth)
  const YResult = getPercentage(secondsPastInYear, secondsInYear)

  let content
  if(tab === H) content = <Percentage percentage={HResult} colors={colors}/>
  if(tab === D) content = <Percentage percentage={DResult} colors={colors}/>
  if(tab === W) content = <Percentage percentage={WResult} colors={colors}/>
  if(tab === M) content = <Percentage percentage={MResult} colors={colors}/>
  if(tab === Y) content = <Percentage percentage={YResult} colors={colors}/>
  if(tab === 'Contact') content = <Contact colors={colors}/>
  if(tab === 'Colors') content = <ColorPicker colors={colors} setColors={setColors}/>

  const refreshClock = () => setDate(new Date())

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return () => clearInterval(timerId)
  }, []);

  const mainStyle = { backgroundColor: getRgbaValue(colors.bg), color: getRgbaValue(colors.font)}
  const headerStyle = { backgroundColor: getRgbaValue(colors.bars), color: getRgbaValue(colors.font)}
  return (
    <main style={mainStyle} className="app flex-container min-vh-100">
      <header style={headerStyle} className='text-center sticky-top'>
        <h1>{getTimeString(date)}</h1>
      </header>
      { content }
      <Tabs options={['Colors', 'Contact', H, D, W, M, Y]} tKey={tab} setTKey={setTab} colors={colors}/>
    </main>
  );
}

function Percentage({ percentage, colors }) {
  const { bg, fg, font } = colors
  const style = { backgroundColor: getRgbaValue(bg), color: getRgbaValue(font)}
  const styleProgress = { width: `${percentage}%`, backgroundColor: getRgbaValue(fg)}
  return (
    <section style={style} className='flex-container flex-content position-relative'>
      <div className='position-absolute top-50 w-100 text-center display-1'>{percentage}%</div>
      <div style={styleProgress} className='flex-content'></div>
    </section>
  )
}

function getTimeString(d) {
  return ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2)
}

function getStoredColors() {
  const saved = localStorage.getItem("colors");
  const initialValue = JSON.parse(saved)
  return initialValue || getDefaultColors();
}



export default App;
