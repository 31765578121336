import getRgbaValue from "../funcs/getRbgaValue"

export default function Tabs({ options,tKey, setTKey, colors }) {
  const tabs = options.map((e, i) => {
    const isActive = e === tKey ? "active" : ""
    let text
    if(e === 'Contact') {
      text = <i className="fa-solid fa-envelope"></i>
    } else if (e === 'Colors') {
      text = <i className="fa-solid fa-palette"></i> //<i className="fa-solid fa-paint-roller"></i>
    } else {
      text = e
    }
    const tabStyle = { color: getRgbaValue(colors.font) }
    const activeTabStyle = { backgroundColor: getRgbaValue(colors.font), color: getRgbaValue(colors.bars), borderColor: getRgbaValue(colors.bars)}
    let innerLine;
    if(isActive) {
      innerLine = <span style={activeTabStyle} className={"nav-link ".concat(isActive)}><span className='text-capitalize'>{text}</span></span>
    } else {
      innerLine = <span style={tabStyle} className={"nav-link ".concat(isActive)}><span className='text-capitalize'>{text}</span></span>
    }
    return (
      <li key={i} className="nav-item clickable" onClick={() => setTKey(e)}>
        { innerLine }
      </li>)
  })
  const styleTabs = { backgroundColor: getRgbaValue(colors.bars) }
  return (
    <div style={styleTabs} className="fixed-bottom d-flex justify-content-around">
       <ul className="nav nav-tabs ">{tabs}</ul>
    </div>
  )
}