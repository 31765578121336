import getRgbaValue from "../funcs/getRbgaValue"

export default function PageInfoItem({title, body, colors}) {
  const style = { backgroundColor: getRgbaValue(colors.fg), color: getRgbaValue(colors.font) }
  return (
    <div className='flex-content m-2'>
      <div className="row">
        <div className="col-sm-4">
          <div className='d-flex h-100 align-items-center justify-content-center'>
            <h2 style={style} className='text-center font-monospace page-info-item-title p-2'>{title}</h2>
          </div>
        </div>
        <div className="col-sm-8 page-info-item-body p-2">
          {body}
        </div>
      </div>
    </div>
  )
}