import { SketchPicker } from "react-color";
import Button from 'react-bootstrap/Button';
import getRgbaValue from '../funcs/getRbgaValue';
import getDefaultColors from "../funcs/getDefaultColors";

export default function ColorPicker({ colors, setColors}) {
  const style = { backgroundColor: getRgbaValue(colors.bg), color: getRgbaValue(colors.font)}
  const styleButton = { backgroundColor: getRgbaValue(colors.fg), color: getRgbaValue(colors.font), borderColor: getRgbaValue(colors.bg)  }
  const handleReset = () => setColors(getDefaultColors())
  return (
    <section style={style} className="container flex-container flex-content pt-4 mb-4">
      <h2 className="text-center">Colour Picker</h2>

      <div className="grid-wrapper">

        <div>
          <p className="text-center">Font Color</p>
          <div className="d-flex justify-content-center p-2">
            <SketchPicker
              onChange={(color) => {
                setColors(prevState => {
                  const updatedValues = { font : color.rgb}
                  return {...prevState, ...updatedValues};
                })
              }}
              color={colors.font}
            />
          </div>
        </div>

        <div>
          <p className="text-center">Foreground</p>
          <div className="d-flex justify-content-center p-2">
            <SketchPicker
              onChange={(color) => {
                setColors(prevState => {
                  const updatedValues = { fg : color.rgb}
                  return {...prevState, ...updatedValues};
                })
              }}
              color={colors.fg}
            />
          </div>
        </div>

        <div>
          <p className="text-center">Background</p>
          <div className="d-flex justify-content-center p-2">
            <SketchPicker
              onChange={(color) => {
                console.log(colors)
                setColors(prevState => {
                  const updatedValues = { bg : color.rgb}
                  return {...prevState, ...updatedValues};
                })
              }}
              color={colors.bg}
            />
          </div>
        </div>

        <div>
          <p className="text-center">Header & Footer</p>
          <div className="d-flex justify-content-center p-2">
            <SketchPicker
              onChange={(color) => {
                setColors(prevState => {
                  const updatedValues = { bars : color.rgb}
                  return {...prevState, ...updatedValues};
                })
              }}
              color={colors.bars}
            />
          </div>
        </div>

      </div>

      <div className="d-flex justify-content-center p-4">
        <Button style={styleButton} className='m-1' size="sm" onClick={handleReset}>Reset Colors</Button>
      </div>
    </section>
  )
}
